<template>
  <div>
    <!-- Destkop -->
    <div class="completed-forms__desktop" v-if="!isMobile">
      <v-col cols="12">
        <v-row class="pl-2">
          <v-col cols="6" class="pa-0">
            <p class="mt-3 text-left completed-forms__title">
              Formularios diligenciados
            </p>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-text-field v-model="search" label="Buscar">
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :search="search"
          :loading="loading.table"
          :items-per-page="10"
          :headers="headers"
          :items="listForms"
          :footer-props="{ itemsPerPageOptions: [5, 10] }"
          class="elevation-1 completed-forms__table"
        >
          <template v-slot:no-results>
            <v-alert text dense type="info" class="mt-3 forms__alert-completed">
              <b>No se encontraron resultados.</b>
            </v-alert>
          </template>
          <template class="pl-0 pr-0" v-slot:no-data>
            <v-alert text dense type="info" class="mt-3 forms__alert-completed">
              <b>No se encontró información.</b>
            </v-alert>
          </template>
          <template v-slot:progress>
            <v-progress-linear
              color="#214484"
              :height="5"
              indeterminate
              stream
            ></v-progress-linear>
          </template>
          <template v-slot:item.documentName="{ item }">
            <div>
              <p class="mb-0 mt-2 text-1-1-lines">
                {{ item.documentName }}
              </p>
            </div>
          </template>
          <template v-slot:item.publicationDate="{ item }">
            <div>
              <p class="mb-0 mt-2 text-1-1-lines">
                {{ item.publicationDate }}
              </p>
            </div>
          </template>
          <template v-slot:item.signatureDate="{ item }">
            <div>
              <p class="mb-0 mt-2 text-1-1-lines">
                {{ item.signatureDate }}
              </p>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-hover v-slot="{ hover }">
              <v-btn
                block
                rounded
                small
                style="color: #fff"
                :style="{
                  'background-color': hover ? '#324CA1' : '#466BE3',
                }"
                @click="ieDetails2(item)"
              >
                Ver
              </v-btn>
            </v-hover>
          </template>
        </v-data-table>
      </v-col>
    </div>

    <!-- Tablet -->
    <div class="forms-completed__tablet" v-else>
      <v-row class="completed__block-tablet">
        <v-col class="mb-0 pb-0 pt-0 mt-0 mb-0 pl-2">
          <p class="text-title-sm pt-0 pb-2 pl-0 mb-0">
            Formularios completados
          </p>
        </v-col>
        <v-alert
          v-if="listForms == null || listForms.length == 0"
          dense
          text
          type="info"
          class="my-4 mx-4"
        >
          No ha respondido ningún formulario
        </v-alert>
        <template v-for="data in listForms">
          <v-col :key="data.id">
            <v-card
              style="width: auto"
              class="border-control-card-p"
              outlined
              elevation="0"
            >
              <v-row>
                <v-col cols="1">
                  <img
                    :src="origin + '/icon/respuestas.svg'"
                    alt="formularios"
                    class="mt-3 ml-4"
                    style="width: 50px"
                  />
                </v-col>
                <v-col style="text-align: initial" cols="9">
                  <p class="ml-8 mb-0 mt-2 text-c-sm-blue text-title-1">
                    <strong> {{ data.title }} </strong>
                  </p>
                  <p class="ml-8" style="font-size: 10px">{{ data.fecha }}</p>
                </v-col>
                <v-col cols="2" class="pl-0">
                  <v-card-actions class="mt-2">
                    <v-btn
                      @click="ieDetails2(data)"
                      rounded
                      block
                      dark
                      class="btn-main mb-2"
                    >
                      Ver
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </div>

    <!-- Mobile -->

    <div class="forms-completed__mobile" v-else>
      <v-row class="completed__block-mobile">
        <v-col class="mb-0 pb-0 pt-0 mt-0 mb-0 pl-2">
          <p class="text-title-sm pt-0 pb-2 pl-0 mb-0">
            Formularios completados
          </p>
        </v-col>
        <v-alert
          v-if="listForms == null || listForms.length == 0"
          dense
          text
          type="info"
          class="my-4 mx-4"
        >
          No ha respondido ningún formulario
        </v-alert>
        <v-col cols="12" class="py-0 px-0">
          <template v-for="data in listForms">
            <v-col :key="data.id">
              <v-card
                style="width: auto"
                class="border-control-card-p"
                outlined
                elevation="0"
              >
                <v-row>
                  <v-col cols="2">
                    <img
                      :src="origin + '/icon/respuestas.svg'"
                      alt="formularios"
                      class="mt-2 ml-4"
                      style="width: 50px"
                    />
                  </v-col>
                  <v-col style="text-align: initial" cols="7">
                    <!-- <v-card-text class="text-left pb-0"> -->
                    <p class="ml-8 mb-0 mt-1 text-title-1 text-c-sm-blue">
                      {{ data.title }}
                    </p>
                    <p class="ml-8 mb-0" style="font-size: 10px">
                      {{ data.fecha }}
                    </p>
                    <!-- </v-card-text> -->
                  </v-col>
                  <v-col cols="3" class="pl-0">
                    <v-card-actions class="mt-2">
                      <v-btn
                        @click="ieDetails2(data)"
                        rounded
                        block
                        dark
                        class="btn-main mb-2"
                      >
                        Ver
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </template>
        </v-col>
      </v-row>
    </div>

    <v-overlay :value="loading.all">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import alert from "@/mixins/alert";
import moment from "moment";
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

export default {
  mixins: [alert],
  name: "Views",
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  data() {
    return {
      origin: window.location.origin,
      listForms: [],
      profileId: null,
      documentNumber: null,
      search: "",
      loading: {
        table: false,
        all: false,
      },
      headers: [
        {
          text: "Nombre de documento",
          value: "documentName",
          align: "start",
          class: "completed-forms__header",
          sortable: false,
        },
        {
          text: "Fecha de publicación",
          value: "publicationDate",
          align: "center",
          class: "completed-forms__header",
        },
        {
          text: "Fecha de firma",
          value: "signatureDate",
          align: "center",
          class: "completed-forms__header",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          class: "completed-forms__header",
          sortable: false,
        },
      ],
      isMobile: false,
    };
  },
  computed: {
    forms() {
      let formsAnswer = this.$store.getters.getFormsAnswer;
      if (formsAnswer) {
        let documents = formsAnswer ? formsAnswer : [];
        documents.forEach((document) => {
          document.documentName = document.title;
          document.publicationDate = moment(document.fecha_creacion).format(
            "L"
          );
          document.signatureDate = moment(document.fecha).format("L");
        });
        documents.sort((a, b) => {
          return moment(b.fecha_creacion).diff(moment(a.fecha_creacion));
        });
        this.listForms = documents;
        this.loading.all = false;
      } else {
        this.loading.all = false;
      }
    },
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    ieDetails2(data) {
      this.$router.push({ name: "formsAnswers", params: { id: data.id } });
    },
    irApp(item) {
      if (item.externarUrl) window.open(item.url, "_black");
      else this.$router.push({ name: item.url });
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
  },
  created() {
    this.profileId = sessionStorage.getItem("id_profile");
    this.documentNumber = sessionStorage.getItem("document_number");
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  watch: {
    forms: function (newCount, oldCount) {
      console.log("");
    },
  },
};
</script>
<style lang="less">
@import "../../assets/css/main";

.completed-forms__desktop {
  display: block;
}

.mdi-chevron-right-circle::before {
  content: "\F0B2A";
  color: #466be3;
}

.mdi-chevron-left-circle::before {
  content: "\F0B28";
  color: #466be3;
}

.border-control-card-p {
  border: 2px solid;
  border-radius: 104px;
  border-color: #3b5bc0 !important;
  border: 1px solid #466be3 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px;
}

// .border-competed-card {
//   border: 2px solid;
//   border-radius: 104px;
//   border-color: #3b5bc0 !important;
// }

// .v-pagination__item {
//   height: 15px !important;
//   min-width: 15px !important;
//   font-size: 1px !important;
// }
// .v-pagination__navigation {
//   box-shadow: none !important;
//   height: 18px !important;
//   width: 18px !important;
// }

// .theme--light.v-pagination .v-pagination__navigation {
//   background-color: #ffffff00;
// }
// .v-pagination__navigation .v-icon {
//   color: #324ba1 !important;
// }
.forms-completed__desktop {
  display: flex;
  width: 100%;
}

.forms-completed__tablet {
  display: none !important;
}

.completed__block-tablet {
  display: none !important;
}

.completed__block-mobile {
  display: none !important;
}

.forms-completed__mobile {
  display: none !important;
}

// .text-title-xl {
//   color: #466be3 !important;
// }

.v-slide-group__next,
.v-slide-group__prev {
  justify-content: center !important;
  flex: 0 1 24px !important;
  min-width: 32px !important;
}

.completed-forms__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 37px;
  color: #353535;
}

.completed-forms__table {
  height: 600px;
  position: relative;
}

.completed-forms__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}

.completed-forms__header {
  color: #353535 !important;
}

tr,
th,
td,
span .completed-forms__table .text-start .sortable {
  font-size: 15px !important;
  font-family: "RobotoRegular" !important;
  max-width: 200px;
}

.completed-forms__table .v-data-footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.forms__alert-completed {
  width: 100%;
}

/* LAPTOP 4k */
@media (max-width: 1900px) {
}

/* LAPTOP LARGE */
@media (max-width: 1600px) {
}

/* LAPTOP LARGE */
@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */
@media (max-width: 1200px) {
}

/* TABLET */
@media (max-width: 1023px) {
  .completed-forms__desktop {
    display: none;
  }

  .forms-completed__desktop {
    display: none !important;
  }

  .forms-completed__tablet {
    display: block !important;
    text-align: center;
  }

  .completed__block-tablet {
    display: block !important;
    text-align: center;
  }

  .completed__block-mobile {
    display: none !important;
  }

  .completed__block-mobile {
    display: none !important;
  }

  .text-title-xl {
    color: #466be3 !important;
  }
  .text-c-sm-blue {
    font-family: "RobotoRegular";
    font-weight: 600;
    font-size: 20px;
    // line-height: 20px;
    color: #466be3;
  }

  .text-title-sm {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #466be3;
    font-family: "RobotoRegular";
  }
}

/* MOBILE L - 767px*/
@media (max-width: 600px) {
  .forms-completed__desktop {
    display: none !important;
  }

  .forms-completed__tablet {
    display: none !important;
  }

  .completed__block-tablet {
    display: none !important;
  }

  .completed__block-mobile {
    display: block !important;
    text-align: center;
    //padding-bottom: 50px;
  }

  .forms-completed__mobile {
    display: block !important;
  }

  #row-mobile {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }

  #col-mobile {
    max-width: 100%;
  }

  #card-mobile {
    //display: flex;
    //width: 296px;
    //height: 140px;
  }

  .text-title-xl {
    color: #466be3 !important;
  }

  .text-title-sm {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #466be3;
    font-family: "RobotoRegular";
  }
  .text-c-sm-blue {
    font-family: "RobotoRegular";
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #466be3;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
}

/* MOBILE S */
@media (max-width: 320px) {
}
</style>
